import React from 'react';
// import ScrollAnimation from 'react-animate-on-scroll';
import Contact from './Contact';
import Portfolio from './Portfolio';
import Services from './Services';
import Testimonials from './Testimonials';
import { TypeAnimation } from 'react-type-animation';
import {
	motion,
	useAnimation,
	useInView,
	useScroll,
	useTransform,
  } from "framer-motion"
  import { useEffect, useRef } from "react"


function Main() {
	const { scrollYProgress: completionProgress } = useScroll()

	const containerRef = useRef(null)
  
	const isInView = useInView(containerRef, { once: true })
	const mainControls = useAnimation()
  
	const { scrollYProgress } = useScroll({
	  target: containerRef,
	  offset: ["start end", "end end"],
	})
	
	  const contactformVal = useTransform(
		scrollYProgress,
		[0, 1],
		["100%", "0%"]
	  )
	
	  useEffect(() => {
		if (isInView) {
		  mainControls.start("visible")
		}
	  }, [isInView])
	
  return (
<div id="main">
<div className="container poppins-extralight">
	<div className="row">
	<h1>Lets Build Websites</h1>

		<h2 class="type-animation"><span>[</span>
		<TypeAnimation
            sequence={[
              // Same substring at the start will only be typed out once, initially
              'Web Development',
              1000,
              'Technical Solutions',
              1000,
              'Seamless Experiences',
              1000,
			  "Development and Delivery",
			  1000,
			  "Coffee To Code",
			  1000
            ]}
            wrapper="span"
            speed={60}
            repeat={Infinity}
          />
		<span>]</span></h2>
		</div>

<div className='row'>
<Services/>
</div>

   <div className='row'>
	<Testimonials/>
	</div>


<div className="actions">
<button  type="button"><a href="#contact-sec">Get In Touch</a></button>
<button type="button"><a href="https://blog.letsbuildwebsites.net/">View The Blog</a></button>
</div>

	<div className='row'>
	<Portfolio/>
	</div>


	<div className='row'>     
    <Contact/>
    </div>

</div>
</div>

  )
}
export default Main