import { projects } from '../constants/constants';

const Portfolio =  () => {

  return (
    <div id="portfolio" className="projects-container">
    <h1>Recent Work</h1>
    {projects.map((project, index) => (
      <div className="project-row" key={index}>
        {/* Image Section */}
        <div className="project-image">
          <img src={project.image} alt={project.title} />
        </div>
        {/* Details Section */}
        <div className="project-details">
          <h3>{project.title}</h3>
          <p>{project.description}</p>
          <div className="project-tags">
            {project.tags.map((tag, i) => (
              <span key={i} className="tag">
                {tag}
              </span>
            ))}
          </div>
          <a href={project.source} target="_blank" rel="noopener noreferrer" className="port-Visit">
            Visit Site
          </a>
        </div>
      </div>
    ))}
  </div>
  );
};

export default Portfolio;