import { ReactSVG } from 'react-svg';
import Robot from './Robot';

function Header() {
    return (
        <div className='header_style'>
<div id='hero'>
    <div class='layer-bg layer' data-depth='0.10' data-type='parallax'>
    
    </div>
    <div class='layer-1 layer' data-depth='0.20' data-type='parallax'>
      <img class="logo" alt="LBW Logo" src="images/Logo.png"/>
    </div>
    <div class='layer-2 layer' data-depth='0.40' data-type='parallax'>
      {/* <ReactSVG class="spaceship" src="images/bodyparts.svg"/> */}
      <Robot/>
    </div>
    <div class='layer-3 layer' data-depth='0.80' data-type='parallax'></div>
    <div class='layer-overlay layer' data-depth='0.85' data-type='parallax'></div>
    <div class='layer-4' data-depth='1.00' data-type='parallax'></div>
</div>

<div id='hero-mobile'></div>
</div>
   )
}
  export default Header