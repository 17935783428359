import React from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";


const RobotAnimation = () => {
  const { ref, inView } = useInView({ threshold: 0.1,  triggerOnce: false }); // Detect scroll
  const controls = useAnimation(); // Control animation state

  React.useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [inView, controls]);

  const variants = {
    hidden: {
      opacity: 0,
      z: -500,
      x: -500,
      scale: 1,
      transition: { duration: 4 },
    },
    visible: {
      opacity: 1,
      z: 0,
      x: 0,
      scale: 1,
      transition: { duration: 3 },
    },
  };

  const variants2 = {
    hidden: {
      opacity: 0,
      y: -500,
      z: -500,
      scale: 1,
      transition: { duration: 4 },
    },
    visible: {
      opacity: 1,
      y: 0,
      z: 0,
      scale: 1,
      transition: { duration: 3 },
    },
  };

  const variants3 = {
    hidden: {
      opacity: 0,
      z: 800,
      scale: 2.0,
      transition: { duration: 2 },
    },
    visible: {
      opacity: 1,
      z: 0,
      scale: 1,
      transition: { duration: 2 },
    },
  };

  const variants4 = {
    hidden: {
      opacity: 0,
      y: -800,
      x: -800,
      scale: 1,
      transition: { duration: 1 },
    },
    visible: {
      opacity: 1,
      y: 0,
      x: 0,
      scale: 1,
      transition: { duration: 1 },
    },
  };

  const variants5 = {
    hidden: {
      opacity: 0,
      x: -600,
      scale: 1,
      transition: { duration: 1 },
    },
    visible: {
      opacity: 1,
      x: 0,
      scale: 1,
      transition: { duration: 1 },
    },
  };

  const variants6 = {
    hidden: {
      opacity: 0,
      y: -800,
      scale: 2.0,
      transition: { duration: 1 },
    },
    visible: {
      opacity: 1,
      y: 0,
      scale: 1,
      transition: { duration: 1 },
    },
  };

  return (
    <div id="robot-container" ref={ref}>
      
<motion.svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1050 1900"
      initial="hidden"
      animate={inView ? "visible" : "hidden"} // Animate based on inView
    >
      {/* Animate the body */}
      <motion.use
        href="/images/bodyparts.svg#head"
        x="50"
        y="150"
        variants={variants}
      />
          {/* Animate the body */}
          <motion.use
        href="/images/bodyparts.svg#torso"
        x="50"
        y="150"
        variants={variants2}
      />
          {/* Animate the body */}
          <motion.use
        href="/images/bodyparts.svg#left-arm"
        x="50"
        y="150"
        variants={variants3}
      />
          {/* Animate the body */}
          <motion.use
        href="/images/bodyparts.svg#right-arm"
        x="50"
        y="150"
        variants={variants4}
      />
          {/* Animate the body */}
          <motion.use
        href="/images/bodyparts.svg#right-leg"
        x="50"
        y="150"
        variants={variants5}
      />
          {/* Animate the body */}
          <motion.use
        href="/images/bodyparts.svg#left-leg"
        x="50"
        y="150"
        variants={variants6}
      />
    </motion.svg>

    </div>
  );
};

export default RobotAnimation;
