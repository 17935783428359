export const projects = [
  {
    title: 'Angstrom360',
    description: "Angstrom360 is a design and build company that specializes in architectural and construction services. The company offers services related to designing and building residential or commercial properties.",
      image: '../images/thumbs/1.png',
      tags: ['Mongo', 'Express', 'React', 'Node', 'Javascript'],
      task: 'The client needed a website to promote their business and attract the right clients. I used Photoshop to design a layout and built them a site once the mock ups were approved using the tech stack below.',
    source: 'https://www.angstromdesignbuild.com/',
    visit: 'https://www.angstromdesignbuild.com/',
    id: 0,
  },
  {
    title: 'Midwest Canvas',
    description:"Midwest Canvas is a Chicago based corporation posed as America's leading manufacturer of pool covers and supplies. From the beginning, our business philosophy has been simple; quality products of superior value, and timely delivery.",
    image: '../images/thumbs/2.png',
    tags: ['HTML', 'JavaScript', 'CSS', 'PHP', 'MySql', 'SEO'],
    task: 'After being contacted by a supervisor of the company on Craigslist I designed and developed the site for the client. This helped the client to increase sales by at least 20% using the SEO skills I applied for the site.',
    source: 'http://www.midwestcanvas.com/',
    visit: 'https://google.com',
    id: 1,
  },
  {
    title: 'Marketron Digital',
    description: "A Chicago based marketing agency providing products and services, users can successfully propose, price and execute multimedia advertising campaigns including television, radio, O&O and digital media.",
      image: '../images/thumbs/3.png',
      tags: ['Angular 2', 'Javascript', 'SASS', 'XML', 'Git Hub'],
      task: 'I was hired on with the company to fill in for another developer that left the company. Using the tech stack below I was able to increase the production of websites for their clients by 30% before the company stop web develoment services.',
    source: 'https://www.marketron.com/',
    visit: 'https://google.com',
    id: 2,
  },
  {
    title: 'Apartments.com',
    description: "Apartments.com is a site to search over 1 million housing listings including apartments, houses, condos, and townhomes available for rent. You’ll find your next home, in any style you prefer.",
    image: '../images/thumbs/4.png',
    tags: ['HTML', 'CSS', 'Javascript', 'ASP', 'Photoshop'],
    task: 'In using the tech stack below I was able to work for the company as a Front-end Web Developer troubleshooting errors, updating the site, run A/B split tests and code out designs submitted by Graphic Designers.',
    source: 'https:www.apartments.com',
    visit: 'https://google.com',
    id: 3,
  },
  {
    title: 'Purohit Navigation',
    description: "The website offers services related to marine navigation, including training courses and consulting services. The company offers Training Courses, Consulting Services and Vessel Traffic Services.",
    image: '../images/thumbs/5.png',
    tags: ['HTML', 'CSS', 'Wordpress', 'PHP', 'Javascript'],
    task: 'I took on a 6 month assignment with Purohit that need various upadtes to their live website and changes in their site design. Using the tech stacks below I was able help the company achieve their desired results.',
    source: 'https://purohitnavigation.com',
    visit: 'https://google.com',
    id: 4,
  },
  {
    title: 'MiMi Productions',
    description: "Mimi Productions is an award winning Chicago Video and Television Production Company. Their clients range from Healthcare, Automotive, Television Networks, Advertising Agencies and many others.",
    image: '../images/thumbs/6.png',
    tags: ['HTML', 'CSS', 'Drupal', 'PHP'],
    task: 'MiMi Productions contected me after I was reffered to by a past co-worker of mine. They needed someone to build a site for them and be able to upload content and make changes to the site on their own. Using Drupal I setup a web site for them and they were able to showcase their work to future clients.',
    source: 'https://www.mimiproductions.com/',
    visit: 'https://google.com',
    id: 5,
  },
];