import {motion} from "framer-motion";

const Testimonials= () => {
return (
    <div className="testimonial-container">
        <h1 className="testimonial-header">Testimonials</h1>
<div className="gtr-uniform">
<motion.figure class="snip1533"
whileInView={{
  opacity: 1,
  scale: 1,
  transition: {
  duration: 1,
  staggerChildren: 5
  }
}}
initial={{
  scale: 0.5,
  when: "beforeChildren",
opacity: 0
}}>
  <figcaption>
    <blockquote>
      <p>I have been using LBW exclusively for website programming and design since 2018. He offers exceptional service and always finds a solution for whatever I need.</p>
    </blockquote>
    <h3>Kristian Ravenclaw</h3>
    <h4><a href="mailto:kristianClaw@midwestcanvas.com.com">Midwest Canvas</a></h4>
  </figcaption>
</motion.figure>
<motion.figure class="snip1533"
whileInView={{
  opacity: 1,
  scale: 1,
  transition: {
  duration: 1.7,
  staggerChildren: 5
  }
}}
initial={{
  scale: 0.5,
  when: "beforeChildren",
opacity: 0
}}>
  <figcaption>
    <blockquote>
      <p>Richard has been wonderful to work with and our website is a million times better than it was. I would recommend him to anyone! </p>
    </blockquote>
    <h3>Michelle De Long</h3>
    <h4><a href="mailto:info@mimiproductions.com"></a>Mimi Productions</h4>
  </figcaption>
</motion.figure>
<motion.figure class="snip1533"
whileInView={{
  opacity: 1,
  scale: 1,
  transition: {
  duration: 1.5,
  staggerChildren: 5
  }
}}
initial={{
  scale: 0.5,
  when: "beforeChildren",
opacity: 0
}}>
  <figcaption>
    <blockquote>
      <p>Richard is a complete joy to work with. Having worked with over a dozen agencies on a number projects, I can say with confidence Richard is one of the developers I'll need for a while. </p>
    </blockquote>
    <h3>Ghazie Muhammad</h3>
    <h4><a href="mailto:gmuhammad@angstrom360.com">Angstrom360</a></h4>
  </figcaption>
</motion.figure>
    </div>
    </div>
);
};

export default Testimonials;