
const Services = () => {
    return (
<div className="container services text-center">
<h1>Services</h1>
<div className="row  justify-content-center">

        <div className="col-lg">
        <i className="fa-solid fa-computer"></i>
        <h2>Website Development</h2>
        <ul>
            <li>Responsive web design</li>
            <li>Custom client branding</li>
            <li>Landing page creation</li>
        </ul>
        </div>

        <div className="col-lg">
        <i className="fa-solid fa-store"></i>
            <h2>Store Development</h2>
        <ul>
            <li>Payment gateway integration</li>
            <li>Product catalog design</li>
            <li>CMS training for clients</li>
        </ul>
        </div>

        <div className="col-lg">
        <i className="fa-solid fa-screwdriver-wrench"></i>
            <h2>Website Updates</h2>
        <ul>
            <li>Regular content updates</li>
            <li>Fixing bugs or errors</li> 
            <li>Performance optimization</li>
        </ul>
        </div>
</div>
</div>

    );
};
    export default Services;