function Footer() {

  window.onload = function DateSet() {
    const d = new Date();
    let year = d.getFullYear();
    document.getElementById("yearTxt").innerHTML = year; 
    };

  return (
    <footer id="footer">
      <p className="footerContact">
      <h6>Contact Info</h6>
      763-485-6584
      <br/>
      <a href="mailto:admin@letsbuildwebsites.net">admin@letsbuildwebsites.net</a>
      </p>
    <div class="inner poppins-regular">
      <ul class="icons">
        <li><a href="https://github.com/digitalev2000" class="fa-brands fa-github"><span class="label"></span></a></li>
        <li><a href="https://dribbble.com/berry_richard" class="fa-brands fa-square-dribbble"><span class="label"></span></a></li>
        <li><a href="mailto:berry.richard1@gmail.com" class="fa-regular fa-envelope"><span class="label"></span></a></li>
      </ul>
      <p class="copyright">
        &copy; <span id="yearTxt"></span> Richard B.<br/>
        Design: <a href="http://www.letsbuildwebsites.net/">Lets Build Websites</a> LLC.
      </p>
      </div>
  </footer>
  )

}

export default Footer