import emailjs from '@emailjs/browser';
import {useRef} from "react";

export const Contact = () => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_bgv8u7l', 'template_8eh2vss', form.current, {
        publicKey: 'Y2exeBg2UofKpBYpK',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          document.getElementById('contact-form').innerHTML = "<div class='sentMsg'><h4>Your Email Was Sent!</h4><p>You will be contacted soon ...</p></div>";
        },
        (error) => {
          console.log('FAILED...', error.text);
          alert("Message NOT Sent!");
        },
      );
  };

  return (
  <div class="row" id="contact-sec" className="contact-form container-fluid">
    <h1>Get In Touch</h1>
 <form ref={form} onSubmit={sendEmail} id="contact-form">
      <div className="row gtr-uniform">

      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
      <input type="text" id="name" name="user_name" placeholder="Name" required/>
      </div>

      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
      <input type="tel" id="phone" name="user_phone" placeholder="Phone" maxLength={10} required/>
      </div>

      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
      <input type="email" id="email" name="user_email" placeholder="Email" required/>
      </div>
      
      <button type="submit" value="Send Message" className="formButton">Send Message</button>
    
      </div>
    </form>
    </div>
  );
};
export default Contact