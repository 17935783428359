
const  Navbar = () => {
    return (
<div>
<nav className="nav">
  <input id="menu" type="checkbox"/>
  <label for="menu">Menu</label>
  <ul className="menu">
    <li>
      <a href="#about">
        <span>About</span>
        <i className="fas fa-address-card" aria-hidden="true"></i>
      </a>
    </li>
    <li>
      <a href="#portfolio">
        <span>Projects</span>
        <i className="fas fa-tasks" aria-hidden="true"></i>
      </a>
    </li>
    <li>
      <a href="#testimonials">
        <span>Testimonials</span>
        <i className="fas fa-users" aria-hidden="true"></i>
      </a>
    </li>
    <li>
      <a href="#contact-sec">
        <span>Contact</span>
        <i className="fas fa-envelope-open-text" aria-hidden="true"></i>
      </a>
    </li>
  </ul>
</nav>
</div>
);
};
export default Navbar;